import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { LoginWidget } from '../Util/LoginWidget';
import SiteContentContext from '../../../SiteContent/SiteContentContext';
import { B2BContext } from '../../../Shop';

const TopBarLinksWrapper = styled('div')`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  > ul {
    > li {
    }
  }
`;

const TopBarLinks = () => {
  const { topBarLinks } = useContext(SiteContentContext);
  const { isB2B } = useContext(B2BContext);
  return (
    <TopBarLinksWrapper className="top-bar-links-list">
      {topBarLinks}
      {isB2B && <LoginWidget />}
    </TopBarLinksWrapper>
  );
};

export default TopBarLinks;
