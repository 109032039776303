import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import CustomFont from './Util/Fonts/CustomFont';
import loadFontCss from '../fonts/loadFontCss';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import { ProductListProvider } from '@jetshop/core/hooks/ProductList/ProductListContext';
import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import LoadingBar from '@jetshop/ui/Loading/LoadingBar';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import React, { createContext, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import { Route, Switch } from 'react-router-dom';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import PagePreviewQuery from './PagePreviewQuery.gql';
import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import LoadingPage from './LoadingPage';
import { Favourites } from './ProductList/Favourites';
import { productListQueries } from './ProductList/productListQueries';
import routeQuery from './RouteQuery.gql';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import './Theming/globalStyles';
import { config } from '../shop.config.custom';
import { theme } from './Theming/Theming';
import SiteContent from './SiteContent/SiteContentProvider';
import NotFoundPage from './NotFoundPage.loadable';
import LogInPage from './Auth/LogInPage.loadable';
import StartPage from './StartPage/StartPage.loadable';
import ProductPage from './ProductPage/ProductPage.loadable';
import CategoryPage from './CategoryPage/CategoryPage.loadable';
import ContentPage from './ContentPage/ContentPage.loadable';
import SearchPage from './SearchPage/SearchPage.loadable';
import SignUpPage from './Auth/Signup/SignUpPage.loadable';
import ForgotPassword from './Auth/ForgotPassword.loadable';
import ResetPassword from './Auth/ResetPassword.loadable';
import MyPages from './MyPages/MyPages.loadable';
import Stores from './Stores/Stores.loadable';
import BlogPage from './Blog/BlogPage.loadable';
import BlogArticle from './Blog/BlogArticle.loadable';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const LogOutPage = loadable(
  () => import('@jetshop/core/components/AuthContext/LogOut'),
  {
    fallback: <LoadingPage />,
  }
);
const PreviewRoute = loadable(
  () => import('@jetshop/core/components/DynamicRoute/PreviewRoute'),
  {
    fallback: <LoadingPage />,
  }
);

export const B2BContext = createContext(null);

function Shop() {
  const { routes } = useShopConfig();

  const { selectedChannel } = useContext(ChannelContext);

  //HANDLING B2B STATES
  const isB2B = selectedChannel.name.search('B2B') !== -1;
  return (
    <GenericError>
      <ModalProvider>
        <B2BContext.Provider
          value={{
            isB2B,
          }}
        >
          <SiteContent>
            <Container>
              <LoadingBar />
              <CustomFont
                primaryFont={theme.font.primary}
                injectCss={loadFontCss}
              />
              <Helmet
                titleTemplate={`%s`}
                defaultTitle={config.settings.storeName}
              />
              <ProductListProvider queries={productListQueries}>
                <Header />
                <Content>
                  <PaginationProvider defaultProductsPerPage={36}>
                    <Switch>
                      <Route
                        exact
                        path={`${config.routes.blog.path}/:cat/:item`}
                      >
                        <BlogArticle configObject={config.routes.blog} />
                      </Route>
                      <Route exact path={`${config.routes.blog.path}/:cat`}>
                        <BlogPage configObject={config.routes.blog} />
                      </Route>
                      <Route exact path={config.routes.blog.path}>
                        <BlogPage configObject={config.routes.blog} />
                      </Route>
                      <Route exact path="/">
                        <StartPage />
                      </Route>
                      <Route path={config.routes.favourites.path}>
                        <Favourites />
                      </Route>
                      <Route path={routes.search.path}>
                        <SearchPage />
                      </Route>
                      <Route path={routes.signup.path}>
                        <SignUpPage />
                      </Route>
                      <Route path={routes.login.path}>
                        <LogInPage />
                      </Route>
                      <Route path={routes.logout.path}>
                        <LogOutPage />
                      </Route>
                      <Route path={config.routes.stores.path}>
                        <Stores />
                      </Route>
                      <Route path={routes.myPages.path}>
                        <MyPages />
                      </Route>
                      <Route exact path={routes.forgotPassword.path}>
                        <ForgotPassword />
                      </Route>
                      <Route path={`${routes.resetPassword.path}/:token`}>
                        <ResetPassword />
                      </Route>
                      <Route
                        path="/preview"
                        render={(props) => (
                          <PreviewRoute
                            productQuery={ProductPreviewQuery}
                            categoryQuery={CategoryPreviewQuery}
                            pageQuery={PagePreviewQuery}
                            productPage={ProductPage}
                            categoryPage={CategoryPage}
                            contentPage={ContentPage}
                            StartPage={StartPage}
                            {...props}
                          />
                        )}
                      />
                      <DynamicRoute
                        routeQuery={routeQuery}
                        productPage={ProductPage}
                        categoryPage={CategoryPage}
                        contentPage={ContentPage}
                        notFoundPage={NotFoundPage}
                        LoadingPage={LoadingPage}
                      />
                    </Switch>
                  </PaginationProvider>
                </Content>
                <Footer />
              </ProductListProvider>
              <ModalRoot />
              <ScrollRestorationHandler
                ignoreForRouteTypes={[
                  'sortOrderChange',
                  'filterChange',
                  'paginationChange',
                ]}
              />
            </Container>
          </SiteContent>
        </B2BContext.Provider>
      </ModalProvider>
    </GenericError>
  );
}

export default Shop;
