import uniBook from './UniNeueBook.woff2';
import uniBookItalic from './UniNeueBook-Italic.woff2';
import uniBold from './UniNeueBold.woff2';
import uniBoldItalic from './UniNeueBold-Italic.woff2';
import uniHeavy from './UniNeueHeavy.woff2';
import uniHeavyItalic from './UniNeueHeavy-Italic.woff2';

import { css } from 'linaria';

export default function loadCss() {
  return css`
    :global(html) {
      @font-face {
        font-family: 'unineue';
        src: url(${uniBook}) format('woff2');
        font-weight: 400;
        font-style: normal;
      }

      @font-face {
        font-family: 'unineue';
        src: url(${uniBookItalic}) format('woff2');
        font-weight: 400;
        font-style: italic;
      }

      @font-face {
        font-family: 'unineue';
        src: url(${uniBold}) format('woff2');
        font-weight: 700;
        font-style: normal;
      }

      @font-face {
        font-family: 'unineue';
        src: url(${uniBoldItalic}) format('woff2');
        font-weight: 700;
        font-style: italic;
      }

      @font-face {
        font-family: 'unineue';
        src: url(${uniHeavy}) format('woff2');
        font-weight: 900;
        font-style: normal;
      }

      @font-face {
        font-family: 'unineue';
        src: url(${uniHeavyItalic}) format('woff2');
        font-weight: 900;
        font-style: italic;
      }
    }
  `;
}
