export const config = {
  routes: {
    favourites: {
      path: process.env.REACT_APP_FAV_PATH || '/favoriter',
    },
    blog: {
      path: process.env.REACT_APP_BLOG_PATH || '/tips-rad',
      id: process.env.REACT_APP_BLOG_ID || 2768,
      mainLevel: 1,
      menu: true,
    },
    stores: {
      path: '/stores',
    },
    brand: {
      id: 154,
    },
    category: {
      hide: false,
    },
    product: {
      hide: false,
    },
  },
  settings: {
    storeName: 'DJO',
    siteContentId: 63,
    siteContentIdB2B: 67,
    categoryIdShowLevel3: 480,
  },
};
